<template>
  <div class="houseRentDetail">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="item">
        <div class="item-l">门店名称</div>
        <div class="item-r">{{detailParams.storeName}}</div>
      </div>
      <!-- <div class="item">
        <div class="item-l">门店类型</div>
        <div class="item-r">网红门店</div>
      </div> -->
      <div class="item">
        <div class="item-l">房间编号</div>
        <div class="item-r">{{detailParams.roomCode}}</div>
      </div>
      <div class="item">
        <div class="item-l">房间状态</div>
        <div class="item-r">{{detailParams.roomStatus}}</div>
      </div>
      <div class="item">
        <div class="item-l">房间类型</div>
        <div class="item-r">{{detailParams.roomTypeName}}</div>
      </div>
      <div class="item">
        <div class="item-l">房间面积</div>
        <div class="item-r">{{detailParams.area}}m²</div>
      </div>
      <div class="item">
        <div class="item-l">租赁价格</div>
        <div class="item-r">{{detailParams.signPrice}}元/月</div>
      </div>
      <!-- <div class="item">
        <div class="item-l">房间信息</div>
        <div class="item-r">朝南房间适合两人居住</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {  getHouseRentDetail } from "./api.js";
export default {
  name: "houseRentDetail",
  components: {},
  props: {},
  data() {
    return {
      detailParams:{}
    };
  },
  created() {
    const { spaceId } = this.$route.query;
    if (spaceId != undefined) {
      this.spaceId = spaceId;
      this.getHouseRentDetail();
    }
  },
  mounted() {},
  methods: {
    getHouseRentDetail() {
      this.$axios
        .get(getHouseRentDetail, {
          params: {
            spaceId: this.spaceId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.detailParams = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.houseRentDetail {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f0f2f5;
  padding-bottom: calc(60px + constant(safe-area-inset-bottom));
  padding-bottom: calc(60px + env(safe-area-inset-bottom));
  .banner {
    width: 100%;
    height: 348px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    box-sizing: border-box;
    padding: 0 30px 0 32px;
    .item {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      box-sizing: border-box;
      padding: 36px 0 34px 0;
      border-bottom: 2px solid #f5f5f5;
      gap: 20px;
      div {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        &:first-child {
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
